import React from 'react';
import '.././index.css';
import WeddStatus from "./wedd-status";
import  SimpleText from './simple-text';
import  Navigation from './navigations';

function HomePage() {
  return (
    <>
      <SimpleText 
        text={'ودینا پلتفرم کارت دعوت دیجیتال و آلبوم عکس آنلاین، جهت ارتباط و ثبت سفارش از طریق تلگرام یا واتس اپ اقدام نمایید'} 
        class={'!z-50 text-lg text-white text-center mt-4 mb-2 py-4'}
      />

      <Navigation/>

      <SimpleText 
        text={'تمام حق و حقوق برای شرکت ودینا محفوظ می باشد'} 
        class={'!z-50 text-sm text-white text-center mt-4 mb-2 py-4'}
      />
    </>
  );
}

export default HomePage;
