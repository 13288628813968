import React from 'react';
import './index.css';
import ConfettiSection from './components/confetti';
import LogoType from './components/logo-type';
import MetaTag from './components/meta-tag';
import BeforeParty from './components/before-party';
import AfterParty from './components/after-party';
import HomePage from './components/home-page';

function App() {
  return (
    <>
    <MetaTag
      title={"Wedina — digital card for your wedding"}
      description={"We look forward to meeting you!"}
      cover={"%PUBLIC_URL%/cover.jpg"}
    />
    <section className="!z-50 bg-scroll flex flex-col p-4 mx-auto my-0 w-[34rem] max-w-full ">
      <LogoType/>
      {/* <BeforeParty/> */}
      {/* <AfterParty/> */}
      {/* <ConfettiSection /> */}
      <HomePage/>

    </section>
    </>
  );
}

export default App;
