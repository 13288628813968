import arrowGo from '../assets/icons/arrowGo.svg'
import wa from '../assets/icons/wa.svg';
import telegram from '../assets/icons/telegram.svg';
import phone from '../assets/icons/phone.svg';
import neshan from '../assets/icons/neshan.svg';

function Navigation() {
    const locations = [
        {
          href: 'https://wa.me/+989351449170?text=%D8%B3%D9%84%D8%A7%D9%85%20%D8%AF%D8%B1%D8%AE%D9%88%D8%A7%D8%B3%D8%AA%20%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87%20%D8%AF%D8%A7%D8%B1%D9%85',
          title: 'واتس اپ',
          icon: wa
        },
        {
          href: 'https://t.me/soroshism',
          title: 'تلگرام',
          icon: telegram
        },
        {
          href: 'tel:+989351449170',
          title: 'تماس  ',
          icon: phone,
          subtitle: '۰۹۳۵۱۴۴۹۱۷۰'
        },
        
      ];

    return(
        locations.map((location) => (
            <a className='!z-50 block my-2 transition duration-300 hover:scale-105' href={location.href}>
                <div className='flex justify-between items-start py-4 px-4 bg-white rounded-xl drop-shadow-lg'>
                    <img className='w-6 h-12' src={arrowGo} alt='' />
                    <div className='flex'>
                        <div className='flex flex-col justify-center text-right mx-2'>
                            <h4 className='text-xl '>
                                {location.title}
                            </h4>
                            {location.subtitle?<small className='text-sm text-slate-500'>{location.subtitle}</small>:''}
                        </div>
                        <img className='w-12 h-12' src={location.icon} alt='' />
                    </div>
                </div>
            </a>
        )))
    }

export default Navigation;
