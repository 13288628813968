import { Helmet } from 'react-helmet';

function MetaTag(props) {

  return(
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={props.cover} />
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex"/>
    </Helmet>
)}

export default MetaTag;
